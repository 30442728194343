@import '../../../Styles/_siteGlobal.scss';
@import '../../SharedConstants.scss';


.fin-risk-result-row {
    padding-top: 1.5em;

    .fin-risk-result-col {
        @media (max-width: 992px) {
            margin-top: 1.5em;
        }

        .fin-risk-result-component {
            .chart-container {
                width: 90%;
                height: 90%;
            }
        }

        .graph-container {
            height: 400px;
        }
    }
}

.fin-risk-details {
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 1em 2em 1em 2em;

    .fin-risk-category-container {
        padding: 0.5em 1em 0.5em 1em;
    }

    .fin-risk-category-header {
        align-items: center;
    }

    .odd {
        background-color: #f5f5f5;
    }

    h3 {
        margin: 0px;
    }

    .negative-value {
        color: #f00;
    }
}
