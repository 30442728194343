div.animated-ellipsis {
    display: flex;
    flex-direction: row;
}

div.animated-ellipsis-default-character {
    width: 4px;
    height: 4px;
    background-color: darkgray;
    border-radius: 2px;
    position: relative;
    top: 5px;
    transition: opacity linear 1s;
}
