﻿:root {
    --normal-button-color: #f05a2b;
    --normal-button-text-color: white;
    --secondary-button-color: #747474;
    --secondary-button-text-color: white;
    --strongbox-button-color: #f05a2b;
    --strongbox-button-text-color: white;
    --disabled-button-color: #c5c5c5;
    --disabled-button-color-borrower: #c5c5c5;
    --disabled-button-text-color: #000;
    --disabled-button-text-color-borrower: #ddd;
    --cancel-button-color: #fcfcfc;
    --cancel-button-text-color: #2e2e2e;
    --row-dividers: rgba(118,118,118,0.1);
    --row-text: black;
    --regular-border: #a7a7a7;
    --error-text: red;
    --color-good: #c1d72e;
    --font-family-primary: sofia-pro, sans-serif;
    --font-default-control-span-size: 16px;
    --input-border: #707070;
    --input-text-color: rgba(32,32,32,0.6);
    --input-background: #e8e8e8;
    --disabled-color: #c5c5c5;
    --light-gray-background: #c5c5c5;
    --off-white-background: #f0f0f0;
    --light-gray-background-text: black;
    --verified-color: #f7971f;
    --unverified-color: #c5c5c5;
    --form-background: white;
    --modal-primary-text: #747474;
    --modal-bold-header: #black;
    --primary-text: #1b1443;
    --column-headers: #707070;
    --emphasis-text: #1b1443;
    --legal-text: #656565;
    --super-happy-text: #black;
    --target-text: #2e2e2e;
    --paragraph-text: #656565;
    --disabled-text: #656565;
    --control-region: #FBFBFB;
    --img-shadow: rgba(160, 160, 160, 0.4);
    --bullet-background: #37474f;
    --normal-link-color: #0366d6;
    --active-nav-link: #f05a2b;
    --black-text: black;
    --dark-border: #303030;
    --banner-height-borrower: 100px;
    --banner-height-lender: 100px;
    --banner-height-lender-slim: 50px;
    --borrower-primary-text: #30474f;
    --borrower-progress-background: #f7971f;
    --borrower-background-color: white;
    --borrower-fading-column-right-gradient: linear-gradient(.28turn, transparent 5%, rgba(235, 235, 235, .99));
    --beta-warning-text: #212529;
    --beta-warning-background: #FFC107;
    --financial-statement-table-header-background: #165D62;
    --financial-statement-table-header-text: white;
    --financial-statement-table-body-highlighted-row: #eeeeee;
    --financial-statement-table-body-regular-text: #666666;
    --financial-statement-table-body-borders: slategray;
    --financial-statement-table-style-button-pressed: lightgray;
    --financial-statement-table-data-even-column: transparent;
    --financial-statement-table-data-odd-column: #dceced;
    --financial-statement-table-item-status-color: white;
    --financial-statement-table-item-status-background: #28c128;
    --financial-statement-table-background: white;
    --strongbox-basic-content-container-background: white;
    --strongbox-basic-content-container-text: black;
    --login-team-selection-container-background: #94aeba;
    --login-team-selection-container-text: white;
    --login-team-selection-container-border: black;
    --login-text-color: black;
    --strongbox-orange: #f05a2b;
    --login-dark-hex-background: #445e6a;
    --light-strongbox-orange: #f7971f;
    --footer-anchor-text: black;
    --extra-row-spacing-extra-small: 10px;
    --extra-row-spacing-small: 20px;
    --extra-row-spacing-medium: 40px;
    --extra-column-spacing-small: 15px;
    --extra-column-spacing-medium: 30px;
    --deleted-row-background: #FF000070;
}

$xs-breakpoint: 576
