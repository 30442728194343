/**
 * Notes on Z-Index values:   
 * 
 * Setting Z-Index to 500000 will not necessarily guarantee that you're on top.  Z-indexing applies within the nearest ancestor 
 * that has a non-static position, e.g. a class with  'position: relative'    Relative is a nice one to use since it is relative
 * to what the static position would be and if you provide no parameters for its position it will be at the same spot it would
 * be with static positioning.
 *  
 * For instance if you have a div with a class containing position: relative, then z-index rules would apply to everything under
 * that div so if one descendent had a z-index of 10 and another had 5 the one with 10 will show on top of the one with 5.
 * Z-Index has NO effect on siblings of the div.  Standard ordering applies in that case.  You could have something with a Z-Index
 * of 50000 as we said before but if a sibling of the div it is under happens to render after this element, it will be on top of it.
 * If the sibling renders before, it would be under it.
 *
 * A pretty good write-up is found here:  https://www.freecodecamp.org/news/4-reasons-your-z-index-isnt-working-and-how-to-fix-it-coder-coder-6bc05f103e6c/
*/

:root {
    --primary-color: #1b1443;
    --border-color: #e3e3e9;

    // Hover and click states
    --light-background-hover: rgba(0, 0, 0, .12);
    --light-background-click: rgba(0, 0, 0, .24);
    --list-item-background-hover: #F4F4F4;

    --header-gray: #404040;
    --light-gray: #E1E1E1;
    --text-gray: #747474;
    --menu-gray-text: #A0A0A0;
}

$modalZIndex: 11;
$notificationZIndex: $modalZIndex + 1;

@mixin shadow($x, $y, $blur: 2px, $alpha: .16) {
    box-shadow: $x $y $blur 0 rgba(0,0,0, $alpha);
}
