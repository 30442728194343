@import '../../Styles/siteGlobal';

.naics-form-group {
    line-height: 3em;
}

.naics-popover {
    .popover {
        max-width: 400px;
    }

    .naics-body {
        .naics-expanded-description {
            display: none;
        }

        .naics-code-descriptor {
            padding: 0px 10px 0px 10px;
            width: 100%;
            cursor: pointer;
        }

        .naics-code-descriptor:hover {
            background-color: var(--list-item-background-hover);
        }

        span {
            font-style: italic;
            cursor: pointer;
        }

        .extra-descriptions {
            margin-top: 10px;
        }
    }
}