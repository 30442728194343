.crumb-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
        padding: 0;
        margin-left: 15px;
    }
}
