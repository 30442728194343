@import '../SharedConstants.scss';
@import '../../Styles/_siteGlobal.scss';

$headerColorText: #767676;

.file-group-verified {
    color: $headerColorText;
    background-color: var(--verified-color);
}

.file-group-unverified {
    color: $headerColorText;
    background-color: var(--unverified-color);
}

span.file-group-verification-status {
    padding: 2px 5px 2px 5px;
    font-size: 12px;
    font-weight: bold;
    height: 22px;
    color: $headerColorText;
}
