@import '../Main.scss';

.add-workspace-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.add-workspace-error-text {
    color: var(--error-text) !important;
}