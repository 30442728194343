@import '../../Styles/siteGlobal';

.loadingOverlay {
    align-items: center;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: $modalZIndex - 1;

    &.light {
        background: rgba(255, 255, 255, 0.7);
    }

    &.dark {
        background: rgba(0, 0, 0, 0.4);
    }
}

.loading-overlay-relative-positioning {
    top: 40%;
    left: 45%;
    position: absolute;
}
