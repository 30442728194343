@import '../../../Styles/_siteGlobal.scss';
@import '../../SharedConstants.scss';

:root {
    --account-no-left: 0px;
    --account-no-width: 115px;
    --caption-options-left: 115px;
    --caption-options-width: 50px;
    --caption-left: 165px;
    --caption-width: 400px;
    --actions-left: 565px;
    --actions-width: 145px;
    --props-left: 705px;
    --line-item-caption-font-size: 12px;
    --caption-max-width: 350px;   // wider than caption-width - caption-left but things work alright
}

.financial-statement-editor {
    .error-state-col, .status-monitor-col {
        border: 3px solid var(--financial-statement-table-header-background);
        padding: 30px;
        margin-bottom: 30px;
        border-radius: 6px;
    }

    .top-row {
        display: flex;
        align-items: center;

        button {
            margin-left: 15px;
        }
    }

    .financial-statement-table-container {
        width: 100%;
        overflow-x: scroll;
        height: 450px;
        border: 2px solid var(--financial-statement-table-header-background);
    }

    .financial-statement-table-dynamic-hover {
        tbody {
            tr:hover {
                background-color: var(--financial-statement-table-body-highlighted-row);

                .fixed-header {
                    background-color: var(--financial-statement-table-body-highlighted-row);
                }

                td.data-table-cell:nth-child(even) {
                    background-color: var(--financial-statement-table-body-highlighted-row);
                }

                td.data-table-cell:nth-child(odd) {
                    background-color: var(--financial-statement-table-body-highlighted-row);
                }

                .fixed-header > div {
                    border-left: 1px solid var(--financial-statement-table-body-highlighted-row);
                }

                .button-bar {
                    visibility: visible;

                    div {
                        visibility: visible;
                    }
                }
            }

            span.line-item:hover {
                padding: 5px 10px 5px 10px;
                border-radius: 3px;
                border: 1px solid var(--financial-statement-table-body-borders);
                position: relative;
                left: -10px;
            }
        }
    }

    .financial-statement-table {
        display: table;

        input[type=checkbox].header {
            background-color: transparent;
            color: var(--financial-statement-table-header-text);
            width: fit-content;
            margin-left: 10px;
            outline: 1px solid var(--financial-statement-table-header-text);
            accent-color: var(--financial-statement-table-header-background);
        }

        button.header {
            color: var(--financial-statement-table-header-text);
            box-shadow: none;
        }

        button.regular-header {
            color: var(--financial-statement-table-header-text);
            padding: 2px 10px 2px 0px;
            background-color: transparent;
            border: 1px solid var(--financial-statement-table-header-text);
            width: fit-content;

            span {
                color: var(--financial-statement-table-header-text);
            }
        }

        .horizontal-fixed-header {
            position: sticky;
            top: 0px;
            min-height: 35px;
        }

        .col-line-item-account-no {
            position: sticky;
            left: var(--account-no-left);
            width: var(--account-no-width);
            min-width: var(--account-no-width);
        }

        .col-line-item-caption-options {
            position: sticky;
            left: var(--caption-options-left);
            width: var(--caption-options-width);
            min-width: var(--caption-options-width);
        }

        .col-line-item-caption {
            position: sticky;
            left: var(--caption-left);
            width: var(--caption-width);
            min-width: var(--caption-width);
        }

        .col-line-item-actions {
            position: sticky;
            left: var(--actions-left);
            width: var(--actions-width);
            min-width: var(--actions-width);
        }

        .col-line-item-props {
            position: sticky;
            left: var(--props-left);
            min-width: 160px;
        }

        thead {
            background-color: var(--financial-statement-table-header-background);
            color: var(--financial-statement-table-header-text);
            text-align: center;
            font-size: 12px;
            border: 1px solid var(--financial-statement-table-header-background);

            button:not(.dropdown-toggle):not(.regular-header) {
                width: 24px;
                height: 24px;
                padding: 2px 0px 0px 0px;
                background: transparent;
                justify-content: center;
                margin-left: 5px;
                border-radius: 2px;
                color: var(--financial-statement-table-header-text);
            }

            .fixed-header-header-region {
                z-index: 100;
                background-color: var(--financial-statement-table-header-background);
            }

            .fixed-header-header-region > div {
                border-left: 1px solid var(--financial-statement-table-header-background);
                border-bottom: 1px solid var(--financial-statement-table-header-background);
                border-top: 1px solid var(--financial-statement-table-header-background);
                background-color: var(--financial-statement-table-header-background);
                position: absolute;
                left: -1px;
                top: -1px;
                right: -1px;
                bottom: -1px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .non-horizontal-fixed-header-header-region {
                z-index: 95;
                height: 36px;
            }

            .non-horizontal-fixed-header-header-region > div {
                border-left: 1px solid var(--financial-statement-table-header-background);
                border-bottom: 1px solid var(--financial-statement-table-header-background);
                border-top: 1px solid var(--financial-statement-table-header-background);
                background-color: var(--financial-statement-table-header-background);
                position: absolute;
                left: -1px;
                top: -1px;
                right: -1px;
                bottom: -1px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            tr {
                height: 50px;
                z-index: 90;
            }

            th {
                width: fit-content;
                min-width: 100px;
            }

            div.button-bar {
                display: flex;
                flex-direction: row;
                justify-content: center;
                color: var(--financial-statement-table-header-text);
                align-items: center;

                span {
                    color: var(--financial-statement-table-header-text);
                }
            }

            div.align-spaced {
                flex-direction: row;
                padding-right: 10px;
                align-items: center;
                justify-content: space-between;
            }

            span {
                min-width: 80px;
                font-size: 12px;
            }
        }

        tbody {
            font-size: 12px;
            text-align: center;
            position: relative;
            top: 2px;

            button:not(.dropdown-toggle):not(.regular-header) {
                width: 24px;
                height: 24px;
                padding: 2px 0px 0px 0px;
                background: transparent;
                justify-content: center;
                margin-left: 5px;
                border-radius: 2px;
                color: var(--financial-statement-table-body-regular-text);
            }

            tr.no-editor {
                height: 35px;
            }

            tr.single-line-ns-editor {
                height: 85px;
                background-color: var(--financial-statement-table-body-highlighted-row);
            }

            tr.double-line-ns-editor {
                height: 155px;
                background-color: var(--financial-statement-table-body-highlighted-row);
            }

            tr {
                display: table-row;
                z-index: 45;

                .fixed-header {
                    z-index: 50;
                    background-color: var(--financial-statement-table-background);
                }

                .fixed-header > div {
                    border-left: 1px solid var(--financial-statement-table-background);
                    border-bottom: 1px solid var(--financial-statement-table-background);
                    border-top: 1px solid var(--financial-statement-table-background);
                    position: absolute;
                    left: -1px;
                    top: -1px;
                    right: -1px;
                    bottom: -1px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .fixed-header > div.left-border {
                    border-left: 1px solid var(--financial-statement-table-header-background);
                }

                td.data-table-cell {
                    text-align: right;
                    padding-right: 4px;
                }

                td.data-table-cell:nth-child(even) {
                    background-color: var(--financial-statement-table-data-even-column);
                }

                td.data-table-cell:nth-child(odd) {
                    background-color: var(--financial-statement-table-data-odd-column);
                }

                .button-bar {
                    display: flex;
                    flex-direction: row;
                    visibility: hidden;
                    justify-content: center;

                    div {
                        display: flex;
                        flex-direction: row;
                        visibility: hidden;
                        justify-content: center;
                        width: 24px;
                        cursor: pointer;

                        input[type="checkbox"] {
                            cursor: pointer;
                        }
                    }

                    .always-visible-item {
                        visibility: visible;
                    }
                }
            }

            tr.locked-line-item {
                background-color: var(--financial-statement-table-body-highlighted-row);

                .fixed-header {
                    background-color: var(--financial-statement-table-body-highlighted-row);
                }

                td.data-table-cell:nth-child(even) {
                    background-color: var(--financial-statement-table-body-highlighted-row);
                }

                td.data-table-cell:nth-child(odd) {
                    background-color: var(--financial-statement-table-body-highlighted-row);
                }

                .fixed-header > div {
                    border-left: 1px solid var(--financial-statement-table-body-highlighted-row);
                }

                .button-bar {
                    visibility: visible;
                }
            }

            td {
                position: relative;
                border: none;
                padding: 0px;
                display: table-cell;
                vertical-align: middle;
                width: fit-content;
            }

            td.left {
                text-align: left;
            }

            td.regular {
                color: var(--financial-statement-table-body-regular-text);
            }

            td.is-selected {
                background-color: var(--financial-statement-table-body-highlighted-row);
            }

            td.is-selected-bordered > div {
                background-color: var(--financial-statement-table-body-highlighted-row);
                border-left: 1px solid var(--financial-statement-table-body-highlighted-row);
            }

            span.status-label {
                cursor: help;
                padding: 3px;
                border-radius: 3px;
                font-size: 12px;
                min-width: 35px;
            }

            span.status-label-green {
                color: var(--financial-statement-table-item-status-color);
                background-color: var(--financial-statement-table-item-status-background);
            }

            span.status-label-empty {
                min-width: 35px;
            }

            span.line-item {
                font-size: var(--line-item-caption-font-size);
                display: inline-block;
                max-width: var(--caption-max-width);
                overflow-x: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            span.detail-account {
                font-size: var(--line-item-caption-font-size);
            }

            .inline-style-editor {
                display: flex;
                flex-direction: row;
                align-items: center;

                div {
                    visibility: hidden;
                }

                div.popover-anchor {
                    position: relative;
                    top: -41px;
                    left: -90px;
                }
            }

            .inline-style-editor:hover {
                div {
                    visibility: visible;
                }
            }

            .bolded {
                font-weight: bold;
            }

            .italic {
                font-style: italic;
            }

            span.single-underline {
                text-decoration: underline var(--financial-statement-table-body-regular-text) solid 1px;
            }

            span.double-underline {
                text-decoration: underline var(--financial-statement-table-body-regular-text) double 1px;
            }

            td.single-underline {
                border-bottom: 1px solid var(--financial-statement-table-body-regular-text)
            }

            td.double-underline {
                border-bottom: 3px double var(--financial-statement-table-body-regular-text);
            }

            .line-item-props {
                font-size: 16px;
                font-weight: bold;
                display: flex;
                flex-direction: row;
                color: var(--financial-statement-table-body-regular-text);
                padding-right: 5px;
            }

            .line-item-props-editable {
                cursor: pointer;
            }

            .line-item-props-readonly {
                cursor: help;
            }

            .line-item-actions-container {
                color: var(--financial-statement-table-body-regular-text);
                border-left: none;
                min-width: 120px;
            }

            span.empty-action {
                display: inline-block;
                width: 24px;
            }
        }
    }

    .name-and-style-editor-labels {
        border-radius: 4px;
        padding: 10px;
    }

    .name-and-style-editor {
        position: relative;
        left: -10px;
        font-size: 12px;

        input[type=text] {
            display: inline-block;
            background: unset;
            padding: 6px 2px 5px 10px;
            color: var(--financial-statement-table-body-regular-text);
        }

        input.bolded {
            font-weight: bold;
        }

        input.italic {
            font-style: italic;
        }

        input.single-underline {
            text-decoration: underline var(--financial-statement-table-body-regular-text) solid 1px;
        }

        input.double-underline {
            text-decoration: underline var(--financial-statement-table-body-regular-text) double 1px;
        }
    }

    .style-button-container {
        border: none;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        padding: 3px 3px 5px 3px;
        margin-top: 2px;
        align-items: center;

        button.style-button {
            width: 40px;
            height: 32px;
            padding: 2px 0px 0px 0px;
            background: transparent;
            color: var(--financial-statement-table-body-regular-text);
            justify-content: center;
            margin-left: 5px;
            margin-right: 0px;
            border-radius: 2px;
            border: 1px solid var(--financial-statement-table-body-regular-text);
        }

        button.dropdown-toggle {
            padding: 4px 10px 4px 10px;
            font-size: 14px;
            font-weight: normal;
            color: var(--financial-statement-table-body-regular-text);
        }

        button.dropdown-item {
            font-size: 14px;
            font-weight: bold;
            padding: 4px 20px 4px 25px;
            color: var(--financial-statement-table-body-regular-text);
        }

        button.pressed {
            background-color: var(--financial-statement-table-style-button-pressed);
        }

        button.right-margin-button {
            margin-right: 15px;
        }

        label {
            margin-bottom: 0px;
            margin-right: 10px;
            margin-left: 20px;
        }

        .MuiInputBase-root {
            font-size: 14px;
            padding: 2px 10px 2px 5px;
            height: 2.4em;
        }
    }

    .style-button-container-right {
        flex-grow: 1;
        flex-wrap: unset;
        flex-direction: row-reverse;
    }

    .style-button-container-shifted {
        position: relative;
        right: -10px;
    }

    .dropdown-button {
        .dropdown-toggle:hover {
            background: var(--strongbox-orange);
        }

        .dropdown-menu {
            transform: translate3d(35px, 37px, 0px);

            div {
                padding: 0;
            }

            button {
                background: transparent;
                color: black;
                font-size: 1rem;
                font-weight: normal;
                box-shadow: none;
                width: 100%;
                margin: unset;
            }

            button:hover {
                background-color: var(--list-item-background-hover);
            }
        }
    }
}

.financial-statement-popover {
    .popover {
    }

    .menu-style {
        font-size: 18px;
        font-weight: bold;
    }

    .popover-body {
        td {
            word-wrap: normal;
            padding: unset;
        }

        .header-column {
            font-weight: bold;
            padding-right: 15px;
        }

        .data-column {
            max-width: 250px;
        }

        .clickable {
            cursor: pointer;
        }

        button:not(.dropdown-toggle):not(.dropdown-menu):not(.dropdown-item):not(.regular-control):not(.style-button) {
            width: 48px;
            height: 32px;
            padding: 0px;
            background: transparent;
            color: var(--financial-statement-table-body-regular-text);
            justify-content: center;
            margin-left: 5px;
            border-radius: 2px;
        }

        div.selectable-row {
            padding: 8px;
            border-radius: 6px;
            cursor: pointer;
        }

        div.selectable-row:hover {
            background-color: var(--financial-statement-table-body-highlighted-row);
        }
    }

    .btn-primary {
        background-color: var(--normal-button-color);
        color: var(--normal-button-text-color);

        &:disabled {
            background-color: var(--disabled-button-color);
            color: var(--disabled-button-text-color);
        }
    }

    .btn-secondary {
        background-color: var(--cancel-button-color);
        color: var(--cancel-button-text-color);
        margin-right: 15px;

        &:disabled {
            background-color: var(--disabled-button-color);
            color: var(--disabled-button-text-color);
        }
    }
}

.financial-statement-move-lineitem-to-new-section-popover {
    min-width: 350px;

    .popover {
    }

    .move-lineitem-body {
        div.col {
            padding-top: 2px;
            padding-bottom: 2px;
        }

        .move-option-top-level {
            font-weight: bold;
        }

        .move-option-enabled:hover {
            background-color: var(--financial-statement-table-body-highlighted-row);
            color: var(--financial-statement-table-body-regular-text);
            cursor: pointer;
        }

        .move-option-disabled {
            color: var(--disabled-color);
        }
    }
}

.add-financial-statement-subsection {
    min-width: 500px;

    label {
        font-size: 18px;
    }

    div.label-column {
        display: flex;
        flex-direction: column;
        padding-top: 4px;
    }

    button.dropdown-toggle {
        color: var(--financial-statement-table-body-regular-text);
    }

    div.row {
        margin-bottom: 10px;
    }
}

.move-financial-statement-valid-destination-list {
    border: 1px solid var(--light-gray);
    padding: 15px;
    max-height: 400px;
    overflow-y: auto;

    .selectable-row {
        cursor: pointer;
        color: var(--financial-statement-table-body-regular-text);
    }

    .selected-row {
        background: var(--financial-statement-table-body-highlighted-row);
    }

    .selectable-row:hover {
        border: 1px solid var(--light-gray);
        box-sizing: content-box;
    }
}