﻿@import '../../Styles/_siteGlobal.scss';
@import '../SharedConstants.scss';

@media only screen and (max-height: 250px) {
    .portal-container-settings {
        height: 100%;

        .portal-settings-row {
            height: 35%;
        }

        h2 {
            font-size: 18px;
        }
    }
}

@media only screen and (min-height: 251px) {
    .portal-container-settings {
        height: 100%;

        .portal-settings-row {
            height: 65%;
        }

        h2 {
            font-size: 18px;
        }
    }
}

@media only screen and (min-height: 450px) {
    .portal-container-settings {
        height: 100%;

        .portal-settings-row {
            height: 80%;
        }

        h2 {
            font-size: 18px;
        }
    }
}

@media only screen and (min-height: 700px) {
    .portal-container-settings {
        height: 100%;

        .portal-settings-row {
            height: 90%;
        }

        h2 {
            font-size: 18px;
        }
    }
}

.active-settings-container {
    border: 1px solid var(--border-color);
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    position: absolute;
    top: 0em;
    bottom: 2em;
    width: 100%;
    height: 95%;

    .header-row {
        border-bottom: 1px solid var(--border-color);
        padding: 1em 1em 1.5em 1em;
        height: 5em;
        align-items: center;
    }

    .content-row {
        overflow-y: auto;
    }

    .column-properties-container {
    }

    .right-aligned-col {
        display: flex;
        justify-content: right;
        padding-right: 0px;
    }
}

.settings-active-child-container {
    height: 100%;

    .full-height-settings-row {
        height: 100%;
        position: relative;
    }

    .most-of-remaining-height-row {
        height: 90%;
    }

    .settings-container-title-row {
        align-items: center;
        margin-top: 2em;
        margin-bottom: 1em;
    }
}

.active-settings-column {
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
}

.settings-user-table {
    border: 1px solid var(--border-color);

    th {
        border: 1px solid var(--border-color);
        padding: .5em 1em .5em .5em;
    }

    td {
        border: 1px solid var(--border-color);
        padding: .5em 1em .5em .5em;
        cursor: pointer;
    }

    tr.selected {
        background-color: var(--light-gray-background);
    }
}

.settings-role {
    color: var(--paragraphtext);
    font-size: 16px;
    padding: 1em 0em 0em 0em;

    .description {
        font-size: 14px;
    }
}

