@import '../../Styles/_siteGlobal.scss';
@import '../SharedConstants.scss';

.enter-workspace-info-container {
    span {
        text-decoration: none;
    }

    span:hover {
        cursor: default;
    }

    .workspace-info-input {
        width: 95%;
        margin-top: 15px;
    }

    input.focus-input {
    }

    input.focus-input:focus {
        outline: 3px solid var(--input-border);
        outline-offset: 2px;
    }

    .input-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    label {
        padding-left: 5px;
    }
}
